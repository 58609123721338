import { Link } from 'gatsby'
import React from 'react'
import Headline from '../components/Headline'
import IntroGradient from '../components/IntroGradient'
import Menu from '../components/Menu'
import Seo from '../components/Seo'
import { sButton } from '../style/globals'
import { s } from '../style'
import LogoFixed from '../components/LogoFixed'

const Page404 = () => {
  return (
    <div css={sCenter}>
      <Seo title='404 ' desc='' url='/404/' />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline
        back='404'
        sBackExtra={{ [s.xs]: { fontSize: '10rem', opacity: 0.3 } }}>
        <h1>
          Strona <mark>nie</mark> istnieje
        </h1>
      </Headline>
      <Link to='/' css={sButton}>
        Powrót na start
      </Link>
    </div>
  )
}

const sCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
}

export default Page404
